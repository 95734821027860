import * as React from "react";
import * as styles from "./category-card.module.css";

import { Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { navigate } from "gatsby";

type CategoryCardType = {
  image?: string;
  label?: string;
  buttonPC?: string;
};

const CategoryCard: FunctionComponent<CategoryCardType> = ({
  image,
  label,
}) => {
  return (
    <div className={styles.categoryCardDiv}>
      <img className={styles.imageIcon} alt="" src={image} />
      <div className={styles.cardTitleDiv}>
        <div className={styles.labelDiv}>{label}</div>
        <Button
          className={styles.button}
          variant="solid"
          colorScheme="gcsblue"
          onClick={() => navigate('/shop')}
        >
          View Products
        </Button>
      </div>
    </div>
  );
};

export default CategoryCard;
