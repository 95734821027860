import * as React from "react";
import * as styles from "./index.module.css";

import { Button, Input, Textarea } from "@chakra-ui/react";
import { FunctionComponent, useCallback } from "react";

import CategoryCard from "../components/category-card";
import ContactForm from "../components/Contact/ContactForm";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";

const Home: FunctionComponent = () => {
  const onButtonClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    navigate("/shop");
  }, []);

  const onButton4Click = useCallback(() => {
    navigate("/log-in");
  }, []);

  return (
    <Layout>
      <div className={styles.homeDiv}>
        <div className={styles.heroSectionDiv}>
          <img className={styles.patternsIcon} alt="" src="/patterns.svg" />
          <div className={styles.heroContentDiv}>
            <div className={styles.heroTextDiv}>
              <div className={styles.titleDiv}>
                <div className={styles.displayDiv}>
                  Welcome to Global Care Supply
                </div>
              </div>
              <div className={styles.pDiv}>
                <div className={styles.captionDiv}>
                  Global wholesale distributor of medical equipment, supplies,
                  and healthcare products.
                </div>
              </div>
            </div>
            <div className={styles.heroActionsDiv}>
              <Button
                className={styles.button}
                variant="ghost"
                colorScheme="gcsblue"
                onClick={onButtonClick}
              >
                About Us
              </Button>
              <Button variant="solid" colorScheme="gcsblue" onClick={onButton2Click}>
                Start Shopping
              </Button>
            </div>
          </div>
          <div className={styles.heroImgLDiv}>
            <img className={styles.imgIcon} alt="" src="/img@2x.png" />
          </div>
        </div>
        <div className={styles.cardsSectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <div className={styles.displayDiv1}>What We Offer</div>
          </div>
          <div className={styles.cardsRowDiv}>
            <div className={styles.cardSlotDiv}>
              <div className={styles.cardDiv}>
                <div className={styles.cardContentDiv}>
                  <div className={styles.titleDiv}>
                    <b className={styles.label}>
                      A one stop solution for all your medical needs
                    </b>
                  </div>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="gcsblue"
                    onClick={onButtonClick}
                  >
                    Learn More
                  </Button>
                </div>
                <div className={styles.cardImgDiv}>
                  <img
                    className={styles.imgIcon}
                    alt=""
                    src="/depositphotos-114702334-s-1@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardSlotDiv1}>
              <div className={styles.cardDiv}>
                <div className={styles.cardContentDiv}>
                  <div className={styles.titleDiv}>
                    <b className={styles.label}>Wholesale Medical Equipement</b>
                  </div>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="gcsblue"
                    onClick={onButtonClick}
                  >
                    Learn More
                  </Button>
                </div>
                <div className={styles.cardImgDiv1}>
                  <img
                    className={styles.depositphotos73011163S1Icon}
                    alt=""
                    src="/depositphotos-73011163-s-1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <div className={styles.displayDiv1}>Product Categories</div>
          </div>
          <div className={styles.categoryCardsDiv}>
            <div className={styles.catCardRowDiv}>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="/image@2x.png"
                  label={`Practice Instruments`}
                  buttonPC="/shop"
                />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="../image1@2x.png"
                  label="Medical Convayance Aid"
                  buttonPC="/shop"
                />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="../image2@2x.png"
                  label="Practice Instruments"
                  buttonPC="/shop"
                />
              </div>
            </div>
            <div className={styles.catCardRowDiv}>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="/image3@2x.png"
                  label="Disposable Practice Supply"
                  buttonPC="/shop"
                />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard image="/image4@2x.png" label="Home Careline" buttonPC="/shop" />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard image="/image5@2x.png" label="Special Orders" buttonPC="/shop" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <div className={styles.displayDiv1}>Why Us</div>
          </div>
          <div className={styles.featuresDiv}>
            <div className={styles.featuresRowDiv}>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/medicinesyrup.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Large Product Range</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/premiumrights.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Low Prices</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/ship.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Fast Delivery</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuresRowDiv}>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/shieldcheck.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Trusted Brands</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/headset.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Customer Service</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/world.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Global Reach</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.contactSectionDiv}
          data-scroll-to="contactSectionContainer"
          id="contactSectionContainer"
        >
          <div className={styles.contactDetailsDiv}>
            <div className={styles.contactDetailsHiddenDiv}>
              <div className={styles.contactDetailsContentDiv}>
                <div className={styles.sectionTitleDiv3}>
                  <div className={styles.displayDiv4}>Need Anything?</div>
                </div>
                <div className={styles.contactInfoDiv}>
                  <div className={styles.labelDiv6}>
                    <div className={styles.labelDiv7}>
                      <span className={styles.emailUsSpan}>Contact us:</span>
                      <span className={styles.span}>{` `}</span>
                      <b> <a href="tel:+1-888-306-5371">+1-888-306-5371</a></b>
                    </div>
                  </div>
                  <div className={styles.labelDiv6}>
                    <div className={styles.labelDiv7}>
                      <span className={styles.emailUsSpan}>
                        Monday to Friday
                      </span>
                      <span className={styles.span}>{` `}</span>
                      <b>9AM - 5PM EST</b>
                    </div>
                  </div>
                </div>
                <div className={styles.buttonForRevealDiv}>
                  <Button
                    className={styles.button3}
                    variant="solid"
                    colorScheme="gcsblue"
                    onClick={onButton4Click}
                  >
                    Sign In To Order
                  </Button>
                </div>
                <div className={styles.labelDiv10}>
                  <div className={styles.labelDiv11}>
                    Please sign in for address
                  </div>
                </div>
                <div className={styles.ccsDiv}>
                  <img className={styles.visaIcon} alt="" src="/visa.svg" />
                  <img
                    className={styles.visaIcon}
                    alt=""
                    src="/mastercard.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contactContentDiv}>
            <img className={styles.patternsIcon1} alt="" src="/patterns1.svg" />

            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO title="Global Care Supply" description="Home page" />
)
