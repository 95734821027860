import * as styles from "../../pages/index.module.css";

import { Button, Input, InputGroup, InputLeftElement, Text, Textarea, useToast } from "@chakra-ui/react"
import React, { useState } from "react";
import { ValidationError, useForm } from '@formspree/react';

import { PhoneIcon } from "@chakra-ui/icons";

export default function ContactForm() {

  var toast = useToast()

  const [state, handleSubmit] = useForm("xwkjpkyg");

  if (state.succeeded) {
    toast({
      status: 'success',
      title: "Message was sent!"
     })
  }

  //initial values + setting new ones by typing in input
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: ""
  })

   const handleChange = (input:any) => (e:any)  => {
     const value = e.currentTarget.value;
     
     setFormValues(prevState => ({
       ...prevState,
      [input]: value
  }));
     
   };

    return (
        <div className={styles.contactFormDiv}>
        <form onSubmit={handleSubmit} className={styles.form}>

            <div className={styles.contactFormInputsDiv}>
              <div className={styles.formTitleDiv}>
                <div className={styles.displayDiv5}>Contact Us</div>
              </div>
              <div className={styles.inputFieldWIthLabelDiv}>
                <div className={styles.inputLabelDiv}>
                  <div className={styles.fullNameDiv}>Full Name</div>
                </div>
                <Input
                  className={styles.input}
                  variant="outline"
                  textColor="#050e15"
                  borderColor="#ebf3fb"
                  focusBorderColor="#b6d07c"  
                  placeholder="John Doe"
                            id="fullName"
                            type="text"
                            name="fullName"
                            required
                            defaultValue={formValues.fullName}
                            onChange={handleChange("fullName")}
                        />
                        <ValidationError  prefix="fullName"  field="fullName" errors={state.errors}/>
              </div>
              <div className={styles.inputFieldWIthLabelDiv}>
                <div className={styles.inputLabelDiv}>
                  <div className={styles.fullNameDiv}>Phone Number</div>
              </div>
              <InputGroup>
               <InputLeftElement
                      pointerEvents="none"
                      children={<PhoneIcon color="gray.300" />}
                    />
                <Input
                  className={styles.input}
                  variant="outline"
                  textColor="#050e15"
                  borderColor="#ebf3fb"
                  focusBorderColor="#b6d07c"
                            placeholder="00 38 69 123 456 789"
                            id="phoneNumber"
                            type="text"
                            name="phoneNumber"
                            required
                            defaultValue={formValues.phoneNumber}
                            onChange={handleChange("phoneNumber")}
                />
                </InputGroup>
              </div>
              <div className={styles.inputFieldWIthLabelDiv}>
                <div className={styles.inputLabelDiv}>
                  <div className={styles.fullNameDiv}>Email</div>
                </div>
                <Input
                  className={styles.input}
                  variant="outline"
                  textColor="#050e15"
                  borderColor="#ebf3fb"
                  focusBorderColor="#b6d07c"
                            placeholder="johndoe@email.com"
                            id="email" type="email" name="email" required defaultValue={formValues.email} onChange={handleChange("email")}
                        />
                        <Text color="red" > <ValidationError prefix="Email"  field="email" errors={state.errors}/></Text>
              </div>
              <div className={styles.inputFieldWIthLabelDiv}>
                <div className={styles.inputLabelDiv}>
                  <div className={styles.fullNameDiv}>Message</div>
                </div>
                <Textarea
                  className={styles.inputTextarea}
                  variant="outline"
                  placeholder="Text Area"
                  textColor="#050e15"
                  borderColor="#ebf3fb"
                    focusBorderColor="#b6d07c"
                    id="Message" name="Message" required defaultValue={formValues.message} onChange={handleChange("message")} 
                        />
                <ValidationError  prefix="Message"  field="Message" errors={state.errors}/>
            </div>
            <div className="g-recaptcha" data-sitekey="6Lf7BAAkAAAAAB_ewV80nCztv4YBaR84v36AgfxR"></div> 
              <Button className={styles.button3} variant="solid" colorScheme="gcsblue" type="submit"> Send Message </Button>
                   
            </div>
        </form>  
    </div>
  )
}
